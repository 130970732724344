<template>
    <div>
      <v-form
         v-on:submit.prevent="DialogAceptar = true"
          ref="form"
          lazy-validation
      >
        <v-card>
        <v-card-title>
          <span class="headline">{{Accion}}</span>
          <v-spacer></v-spacer>
          <span v-if="Item.id != 0" class="headline">ID: {{Item.id}}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
             <v-row>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="Item.titulo"
                  label="Titulo *"
                  hint="Titulo de la notificación"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  auto-grow
                  counter
                  label="Mensaje"
                  v-model="Item.mje"
                  hint="Escriba el mensaje de la notificación."
                  rows="1"
                  persistent-hint
                  required
                ></v-textarea>
              </v-col>

              <v-col cols="12" v-if="Item.id > 0">
                <v-textarea
                  auto-grow
                  counter
                  label="Usuarios"
                  v-model="Item.usuario"
                  hint="Estos son los id de los usuarios que han aceptado la notificación."
                  rows="1"
                  persistent-hint
                  required
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
              >
               <v-select
                  v-model="Item.activo"
                  :items="SINO"
                  item-text="titulo"
                  item-value="id"
                  menu-props="auto"
                  label="La Notificación está activa?"
                  hint="Indique si la notificación se encuentra activa en el sistema"
                  persistent-hint
                  :rules="[v => v >= 0 || 'Item Requerido']"
                  required
                ></v-select>
              </v-col>

            </v-row>
          </v-container>

          <small>* indica campo requerido</small>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="Ocultar()">Cerrar</v-btn>
          <v-btn color="primary" type="submit">Guardar</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>

     <v-dialog v-model="DialogAceptar" persistent max-width="380">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{Accion}}
        </v-card-title>

        <v-card-text class="mt-4">
          <h2>¿Estas seguro de guardar?</h2>
        </v-card-text>
        
        <v-divider></v-divider>

        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="error" @click="DialogAceptar = false">Cancelar</v-btn>
          <v-btn color="primary" @click="Guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'

export default ({

  props:['Accion','Item','NuevoActivo'],

  watch:{
      Alerta(value){
        if(value.error == false){
          this.Ocultar()
        }
      }
  },

  created (){
    
  },

  data: () => ({
    var: null,
    DialogAceptar: false,

    ItemEnviar: {
          id: 0,
          titulo: null,
          mje: null,
          usuario: '',
          activo: 1,
          },
  }),

  methods: {
     ...mapActions(['NuevoNotificacionesTabla', 'ConsultarNotificacionesTabla']),

    async Guardar (){

      this.ItemEnviar.id = this.Item.id
      this.ItemEnviar.titulo = this.Item.titulo
      this.ItemEnviar.mje = this.Item.mje
      this.ItemEnviar.usuario = this.Item.usuario
      this.ItemEnviar.activo = this.Item.activo

      await this.NuevoNotificacionesTabla(this.ItemEnviar)
      await this.ConsultarNotificacionesTabla()
      this.DialogAceptar = false
    },

    Ocultar(){
        this.$emit('NuevoActivo', false)
    }
  },

  computed:{
    ...mapState(['Alerta']),
    ...mapGetters(['SINO'])
  }


})
</script>